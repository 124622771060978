// Variable overrides first
$primary: #3B4664;
$secondary: #E3E9F3;
$enable-shadows: true;
$prefix: "mo-";

$enable-grid-classes: false;
$enable-cssgrid: true;

// Then import Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

.service-item__card {
	display: flex;
	align-items: center; 
}

.service-item__card h3{
	font-size: 1.1rem;
	font-weight: bold;
}

.service-item__icon {
	background-color: $secondary;
	display: flex;
	flex: 0 0 auto;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	margin-right: 1rem;
	color: $primary;
	font-size: 1.5rem;
}

footer a {
	color: $secondary;
}


.p-section {
	padding-top: 1.6rem;
	padding-bottom: 1.8rem;
}
@include media-breakpoint-up(md) {
	.p-section {
		padding-top: 2.375rem;
		padding-bottom: 2.75rem;
	}
 }
@include media-breakpoint-up(lg) {
	.p-section {
		padding-top: 4.75rem;
		padding-bottom: 5.5rem;
	}
 }